.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.card {
  display: flex;
  width: 80%;
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.left-section {
  flex: 3.5;
  display: flex;
  flex-direction: column;
}

.sub-id {
  font-weight: bold;
  font-family: 'Founders Grotesk';
  color: #d83e2c;
  font-weight: 400;
  font-size: 1.5rem;
}

.kit-name {
  font-weight: 700;
  font-size: 2rem;
  font-family: 'GTSuperDSTrial';
  color: #1f1e21;
  margin-top: 1rem;
}

.right-section {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.section-1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}
.sub-detail {
  font-family: 'Founders Grotesk Medium';
  font-weight: 500;
  font-size: 1.5rem;
  color: #1f1e21;
}
.sub-type {
  font-family: 'Founders Grotesk';
  color: #4e4b53;
  font-weight: 400;
  font-size: 1.5rem;
  margin-top: 0.5rem;
}
.sub-date {
  font-family: 'Founders Grotesk';
  color: #4e4b53;
  font-weight: 400;
  font-size: 1.5rem;
  margin-top: 0.2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.section-2 {
  display: flex;
  flex: 1;
  min-height: 100%;
  justify-content: center;
  align-items: center;
}

.modify-button {
  background-color: transparent;
  color: #007f61;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Founders Grotesk Medium';
  font-weight: 500;
  font-size: 1.8rem;
}

@media (max-width: 768px) {
  .card {
    flex-direction: column;
    align-items: center;
  }

  .left-section,
  .right-section {
    flex: 1;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }

  .sub-id {
    font-size: 1.2rem;
  }

  .kit-name {
    font-size: 1.5rem;
  }

  .right-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .sub-detail,
  .sub-type,
  .sub-date {
    font-size: 1.2rem;
  }

  .modify-button {
    font-size: 1.4rem;
  }
}

.modify-button:hover {
}

.updateSubModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.updateSubModal-content {
  width: 40vw;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  border-radius: 1rem;
}
.updateSubModal-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.updateSubModal-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.updateSubModal-title {
  font-weight: 500;
  font-size: 2rem;
  font-family: 'Founders Grotesk Medium';
}
.updateSubModal-navPanel {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.skipNextShipment-cont {
  margin-top: 2rem;
}
.text-input {
  width: 100%;
  /* height: 3rem; */
  border: 1px solid #d8d8d8;
  padding: 0.5rem;
  font-family: 'Founders Grotesk';
  font-size: 2rem;
  color: #1f1e21;
  margin: 1rem 0;
}
.textInput-cont {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 1rem 0;
  margin-bottom: 3rem;
}
.text-input-label {
  font-weight: 500 !important;
  font-size: 1.8rem !important;
  font-family: 'Founders Grotesk Medium' !important;
  color: #1f1e21 !important;
}
.skipNextShipment-btt-cont {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 4rem;
}
.updateSubModal-cancel-bttn {
  background-color: transparent;
  color: #007f61;
  padding: 8px 26px;
  border: 1px solid #007f61;
  border-radius: 24px;
  cursor: pointer;
  font-family: 'Founders Grotesk Medium';
  font-weight: 500;
  font-size: 1.8rem;
  margin-right: 1rem;
}
.updateSubModal-send-bttn {
  width: 100% !important;
  max-width: 250px !important;
  background-color: #007f61;
  color: #fff;
  padding: 8px 26px;
  border: 1px solid #007f61;
  border-radius: 24px;
  cursor: pointer;
  font-family: 'Founders Grotesk Medium';
  font-weight: 500;
  font-size: 1.8rem;
}

@media (max-width: 1024px) {
  .updateSubModal-content {
    width: 70vw;
  }
}

@media (max-width: 768px) {
  .updateSubModal-content {
    width: 90vw;
  }

  .updateSubModal-title {
    font-size: 1.8rem;
  }

  .text-input {
    font-size: 1.6rem;
  }

  .updateSubModal-cancel-bttn,
  .updateSubModal-send-bttn {
    font-size: 1.6rem;
    padding: 8px 20px;
    margin-right: 0.5rem;
  }

  .skipNextShipment-btt-cont {
    margin-top: 2rem;
    justify-content: space-between;
  }
  .updateSubModal-navPanel {
    flex-direction: column;
  }
}

.updateSubModal-send-bttn.loading {
  position: relative;
  color: #007f61;
}

/* Style the loading spinner */
.updateSubModal-send-bttn.loading::after {
  content: '';
  width: 20px;
  height: 20px;
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  /* margin-top: -10px; */
  margin-left: -10px;
  animation: spin 1s linear infinite;
}

/* Keyframe animation for the loading spinner */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.no-subscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10rem;
}

.no-subscription-title {
  font-family: 'Founders Grotesk Medium';
  font-weight: 500;
  font-size: 3rem;
  color: #979797;
  margin-bottom: 1rem;
}
.helper-text {
  font-weight: 400 !important;
  font-size: 1.4rem !important;
  font-family: 'Founders Grotesk Medium' !important;
  color: #1e1e1f !important;
}
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  flex-direction: column;
  gap: 1rem;
}
.loading {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  margin: 20px auto;
  color: white;
}

/* Subscription Details */
/* Beautiful Styling for ManageSubscription Components */

/* Stripe Portal Card */
.stripe-portal-wrapper {
  width: 100%;
  margin: 2rem auto;
  display: flex;
  justify-content: center;
}

.stripe-portal-card {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  max-width: 700px;
  width: 100%;
  transition: box-shadow 0.2s ease;
  border: 1px solid #ececec;
}

.stripe-portal-card:hover {
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.15);
}

.stripe-portal-left {
  margin-right: 20px;
  color: #007f61;
}

.portal-icon {
  font-size: 36px;
}

.stripe-portal-right {
  flex: 1;
}

.portal-title {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.portal-desc {
  margin: 8px 0 16px 0;
  font-size: 14px;
  color: #666;
}

.portal-button {
  background-color: #007f61;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-weight: 500;
  border-radius: 6px;
  transition: background-color 0.2s ease;
}

.portal-button:hover {
  background-color: #dd7245;
}

/* Shipment Control Section */
/* Beautiful Styling for ManageSubscription Components */

/* Stripe Portal Card (used as base for other boxes too) */
.manage-subscription {
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.stripe-portal-wrapper,
.shipment-controls,
.updateSubModal-content {
  margin: 2rem auto;
  display: block;
  justify-content: center;
  max-width: 700px;
}

.stripe-portal-card,
.shipment-controls,
.updateSubModal-content {
  background-color: #ffffff;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);
  border: 1px solid #ececec;
  width: 100%;
}

.stripe-portal-card:hover,
.updateSubModal-content:hover,
.shipment-controls:hover {
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.15);
}

.stripe-portal-left {
  margin-right: 20px;
  color: #007f61;
}

.portal-icon {
  font-size: 36px;
}

.stripe-portal-right {
  flex: 1;
}

.portal-title {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.portal-desc {
  margin: 8px 0 16px 0;
  font-size: 14px;
  color: #666;
}

.portal-button {
  background-color: #007f61;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-weight: 500;
  border-radius: 6px;
  transition: background-color 0.2s ease;
}

.portal-button:hover {
  background-color: #dd7245;
}

/* Input Sections and Button Styling */
.textInput-cont {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.text-input-label {
  margin-bottom: 6px;
  font-size: 14px;
  font-weight: 500;
  color: #444;
}

.text-input {
  padding: 8px 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 6px;
}

.updateSubModal-send-bttn {
  background-color: #007f61;
  color: white;
  border: none;
  padding: 10px 16px;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  width: fit-content;
  transition: background 0.3s ease;
}

.updateSubModal-send-bttn:hover {
  background-color: #dd7245;
}

.updateSubModal-cancel-bttn {
  background-color: transparent;
  border: 1px solid #ccc;
  padding: 10px 16px;
  border-radius: 6px;
  margin-right: 10px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.updateSubModal-cancel-bttn:hover {
  border-color: #007f61;
  color: #007f61;
}

.skipNextShipment-btt-cont {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 10px;
}

/* Checkboxes and Misc */
.checkbox-label {
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  gap: 10px;
}

.checkbox-custom {
  width: 16px;
  height: 16px;
  border: 2px solid #ccc;
  border-radius: 4px;
  display: inline-block;
}

input[type='checkbox']:checked + .checkbox-custom {
  background-color: #007f61;
  border-color: #007f61;
}

.helper-text {
  font-size: 13px;
  color: #888;
  margin-top: 8px;
}

.custom-date-input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
  align-items: center;
}

.text-input-label {
  font-size: 14px;
  font-weight: 500;
  color: #444;
  margin-bottom: 6px;
}

.styled-calendar {
  width: 100%;
  padding: 10px 12px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 6px;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  transition: border-color 0.2s ease;
}

.styled-calendar:hover {
  border-color: #007f61;
}

.styled-calendar:focus {
  border-color: #007f61;
  outline: none;
  box-shadow: 0 0 0 2px rgba(238, 129, 85, 0.2);
}

.basic-center {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
}

.a-clone {
  /* // i want tto make styling liek there is for <a> tag */
  text-decoration: none;
  color: #007f61;
  font-weight: 500;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.skip-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.skip-modal {
  background: white;
  padding: 2rem;
  border-radius: 12px;
  width: 90%;
  max-width: 400px;
  text-align: center;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.2);
}

.skip-modal h4 {
  font-size: 20px;
  margin-bottom: 1rem;
}

.skip-modal p {
  font-size: 14px;
  color: #555;
  margin-bottom: 1.5rem;
}

.skip-modal-buttons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.skip-cancel-button,
.skip-confirm-button {
  flex: 1;
  padding: 10px 0;
  font-weight: 600;
  border-radius: 6px;
  cursor: pointer;
  border: none;
}

.skip-cancel-button {
  background-color: #eee;
  color: #333;
}

.skip-confirm-button {
  background-color: #ee8155;
  color: white;
}

.modala-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modala {
  background-color: #ffffff;
  padding: 4rem 4rem;
  border-radius: 12px;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.2);
  text-align: center;
  z-index: 1000;
}

.modala h3 {
  margin-bottom: 1rem;
  font-size: 20px;
  color: #222;
}

.modala p {
  font-size: 14px;
  color: #555;
  margin-bottom: 1.5rem;
}

.modala-buttons {
  display: flex;
  justify-content: space-between;
  gap: 12px;
}

.modala-button {
  flex: 1;
  padding: 10px 16px;
  font-weight: 600;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.modala-button.cancel {
  background-color: #e0e0e0;
  color: #333;
}

.modala-button.cancel:hover {
  background-color: #d5d5d5;
}

.modala-button.confirm {
  background-color: #ee8155;
  color: white;
}

.modala-button.confirm:hover {
  background-color: #d66c3e;
}